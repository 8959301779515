import React, { useEffect, useState } from 'react';
import './index.scss'

import Logo from '../../assets/img/Logo Inovando_Positivo.png'

const Header = () => {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => {
            setOffset(window.pageYOffset)
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);


    const handleClickScroll = (item) => {
        const element = document.getElementById(`${item}`);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };

    return (
        <div className={`header ${offset > 10  ? `shadow` : ''} `} >
            <div className='container'>
            <div className='logo' src={Logo} alt=""></div>
            <nav>
            <ul className='nav'>
                <li onClick={()=>handleClickScroll("who")} > <a className='hover-underline-animation' href="javascript:void(0);">Quem somos</a> </li>
                <li onClick={()=>handleClickScroll("our")} ><a className='hover-underline-animation' href="javascript:void(0);"> Nosso processo</a></li>
                <li onClick={()=>handleClickScroll("tech")}> <a className='hover-underline-animation' href="javascript:void(0);">Tecnologias</a> </li>
            </ul>
            </nav>
            <a target="_blank" href="https://web.whatsapp.com/send?phone=+558183412991&text=Olá gostaria de saber mais sobre desenvolvimento de site ou aplicativo." className='fale-conosco text'>Fale conosco</a>
        </div>
        </div>
    );
};

export default Header;
