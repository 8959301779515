import React from 'react';
import './index.scss'
import Logo from '../../assets/img/Logo Inovando_Positivo.png'
import Insta from '../../assets/img/Logo 2.png'
import Linkedin from '../../assets/img/Logo 11.png'
import CookieConsent, { Cookies } from "react-cookie-consent";

const Footer = () => {
    return (
        <div className='footer full-w'>
            <CookieConsent
            overlay
            location="bottom"
            buttonText="Aceito"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
         Bem-vindo(a) ao nosso site! Para fornecer uma experiência personalizada e permitir que você aproveite todos os recursos do nosso serviço, utilizamos cookies e solicitamos o seu nome e endereço de e-mail para preenchimento de um formulário de proposta. A privacidade dos seus dados é de extrema importância para nós, e queremos que você esteja completamente informado(a) sobre como essas informações são utilizadas.
            {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
            </CookieConsent>
            <div className='container'>
            <div className='box-logo'>
                <div className="logo-row">
                    <div className='logo' src={Logo} alt=""></div>
                    <div className='midias'>
                        <img src={Insta} alt="instagram logo" onClick={()=> window.open("https://www.instagram.com/inovando.consultoria/")} />
                        <img src={Linkedin} alt="linkedin logo" />
                    </div>
                </div>
                <p>Recife, 2022 <br /> <br /></p>
            </div>
            <div className='box-text'>
            <p>Rua Alfândega, 35 – Loja 0401 <br />
                Bairro do Recife  <br />
                Shopping Paço Alfândega – Cx.PT 053  <br />
                Recife – PE | CEP: 50030-030</p>
            </div>
            </div>
        </div>
    );
};

export default Footer;