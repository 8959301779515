import React from 'react';
import './index.scss'

const MaintenancePage = () => {
  return (
    <div className="maintenance-page">
      <h1 className="title">Site em manutenção</h1>
      <p className="message">Nos desculpe, mas o site está passando por manutenção no momento.</p>
      <p className="message">Estamos trabalhando para tornar a experiência do usuário ainda melhor.</p>
      <p className="message">Por favor, tente novamente mais tarde.</p>
    </div>
  );
}

export default MaintenancePage;